@tailwind base;
@tailwind components;
@tailwind utilities;
/* Reset default styles */
body, h1, p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}


/* Reset CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}


.dash {
  stroke-dasharray: 700;
  animation: dash 15s linear infinite;
  stroke: rgb(124, 58, 237);
}

.dash_0 {
  animation-delay: 0s;
  rotate: -45deg;
  transform: translateY(1000px);

}

.dash_1 {
  transform: translateY(1100px);
  rotate: -45deg;
  animation-delay: 2.5s;
}

.dash_2 {
  transform: translateY(1200px);
  rotate: -45deg;
  animation-delay: 6s;
}

.dash_3 {
  transform: translateY(1300px);
  rotate: -45deg;
  animation-delay: 4s;
}

.dash_4 {
  transform: translateY(1400px);
  rotate: -45deg;
  animation-delay: 1s;
}

.dash_5 {
  transform: translateY(1200px);
  rotate: -45deg;
  animation-delay: 5s;
}

.dash_6 {
  transform: translateY(1600px);
  rotate: -45deg;
  animation-delay: 3s;
}

.dash_7 {
  transform: translateY(900px);
  rotate: -45deg;
  animation-delay: 1.5s;
}

.dash_8 {
  transform: translateY(1800px);
  animation-delay: 5.5s;
  rotate: -45deg;

}

.dash_9 {
  transform: translateY(1000px);
  animation-delay: 1s;
  rotate: -45deg;

}

@keyframes dash {
  0% {
    stroke-dashoffset: 10;
    stroke: #8a2efb;
  }

  50% {
    stroke: #660ac2;
  }

  100% {
    stroke-dashoffset: 9800;
    stroke: #8404c5;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (max-width: 768px) {
  .scoreboard {
      flex-direction: column;
  }

  .team {
      margin-bottom: 20px;
  }
}

.scoreboard {
  font: 'Poppins';
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #256EA3;
  size: 50px;


}

.team {
  margin: 0 20px; /* Adjust the margin as needed */
}

.team h2 {
  text-align: center;
  padding: 100px;
}

.player {
  text-align: center;
  padding: 100px;
}

.team {
  text-align: center;
  padding: 100  px;
}


.score {
  font: 'poppins';
  font-weight: 400;
  font-size: 59px;
  color: #256EA3;
  width: 75px ;
  height: 89px;
  top: 795px;
  left: 476px;
  line-height: 70.5px

}

.buttons {
  margin-top: 10px;
}